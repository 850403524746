<template>
  <v-app>
    <pages-core-view />

    <pages-core-footer />
    <Snackbar />
    <Snackbar />
  </v-app>
</template>

<script>
export default {
  name: "PagesIndex",

  components: {
    PagesCoreAppBar: () => import("./components/core/AppBar"),
    PagesCoreFooter: () => import("./components/core/Footer"),
    PagesCoreView: () => import("./components/core/View"),
    Snackbar: () => import("../../components/Snackbar"),
  },

  created() {
    this.$vuetify.theme.dark = true;
  },

  beforeDestroy() {
    this.$vuetify.theme.dark = false;
  },
};
</script>
